import {DtrackProject, Finding, ProjectMetrics} from './type';
import { createApiRef } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';

export const dtrackApiRef = createApiRef<DtrackApi>({
    id: 'plugin.dtrack.service',
});

export interface DtrackApi {
    fetchProject(
        entity: Entity,
    ): Promise<DtrackProject>;

    fetchFindings(
        entity: Entity,
    ): Promise<Finding[]>;

    fetchMetrics(
        entity: Entity
    ): Promise<ProjectMetrics>;

    getDtrackGraph(
        entity: Entity
    ): Promise<Object>;
}