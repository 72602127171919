import {DtrackGraphResponse, DtrackProject, Finding, ProjectMetrics} from "./type";
import {DtrackApi} from "./api";
import {getDtrackProjectId} from "./annotations";
import {DiscoveryApi, IdentityApi} from '@backstage/core-plugin-api';
import {Entity} from '@backstage/catalog-model';

export class ProductionDtrackApi implements DtrackApi {
    dependencyObject = {
        id: "",
        label: "",
        children: []
    }

    constructor(
        private readonly discoveryApi: DiscoveryApi,
        private readonly identityApi?: IdentityApi,
    ) {
    }

    async fetchMetrics(entity: Entity): Promise<ProjectMetrics> {
        const project = getDtrackProjectId(entity);

        const apiUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/dependencytrack`;
        const authOpts = await this.authOptions();

        const metricsResponse = await fetch(
            `${apiUrl}/api/v1/metrics/project/${project}/current`,
            authOpts
        );

        if (metricsResponse.status >= 400 && metricsResponse.status < 600) {
            throw new Error('Failed fetching expanded metrics');
        }

        return (await metricsResponse.json()) as ProjectMetrics;

    }

    async fetchFindings(
        entity: Entity
    ): Promise<Finding[]> {
        const project = getDtrackProjectId(entity);

        const apiUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/dependencytrack`;
        const authOpts = await this.authOptions();

        const findingsResponse = await fetch(
            `${apiUrl}/api/v1/finding/project/${project}`,
            authOpts
        );

        if (findingsResponse.status >= 400 && findingsResponse.status < 600) {
            throw new Error('Failed fetching expanded findings');
        }

        return (await findingsResponse.json()) as Finding[];
    }

    async fetchProject(
        entity: Entity,
    ): Promise<DtrackProject> {
        const project = getDtrackProjectId(entity);

        const apiUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/dependencytrack`;
        const authOpts = await this.authOptions();

        const simpleResponse = await fetch(
            `${apiUrl}/api/v1/project/${project}`,
            authOpts
        );

        if (simpleResponse.status >= 400 && simpleResponse.status < 600) {
            throw new Error('Failed fetching Dependencytrack project');
        }

        return (await simpleResponse.json()) as DtrackProject;
    }

    async getDtrackGraph(
        entity: Entity,
    ) {
        const project = getDtrackProjectId(entity);

        const apiUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/dependencytrack`;
        const authOpts = await this.authOptions();

        const simpleResponseProject = await fetch(
            `${apiUrl}/api/v1/project/${project}`,
            authOpts
        );

        if (simpleResponseProject.status >= 400 && simpleResponseProject.status < 600) {
            throw new Error('Failed fetching project dependencies');
        }

        let projectInfo = (await simpleResponseProject.json()) as DtrackProject;

        const simpleResponse = await fetch(
            `${apiUrl}/api/v1/dependencyGraph/project/${project}/directDependencies`,
            authOpts
        );

        if (simpleResponse.status >= 400 && simpleResponse.status < 600) {
            throw new Error('Failed fetching project dependencies');
        }

        let projectDependencies = (await simpleResponse.json()) as DtrackGraphResponse[];
        let fatherComponent: string[] = [];

        let dependencies = Object.create(this.dependencyObject)
        dependencies.id = 0;
        dependencies.label = projectInfo.name;
        dependencies.children = await this.loopDependencies(apiUrl, authOpts, projectDependencies, fatherComponent);

        return dependencies;
    }

    private async authOptions() {
        if (!this.identityApi) {
            return {};
        }
        const {token} = await this.identityApi.getCredentials();
        return {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
    }

    private async loopDependencies(apiUrl: string, authOpts: any, elements: DtrackGraphResponse[], fatherComponent: string[]) : Promise<Object[]> {
        let dependencies: Object[] = [];

        for (let i = 0; i < elements.length; i++) {
            let component = elements[i];
            if(fatherComponent.includes(component.uuid)) {
                let dependency = Object.create(this.dependencyObject);
                dependency.id = i;
                dependency.label = component.name + "@" + component.version;
                dependencies.push(dependency);
                continue;
            }

            fatherComponent.push(component.uuid);

            let dependency = Object.create(this.dependencyObject);
            dependency.id = i;
            dependency.label = component.name + "@" + component.version;

            if(!(typeof component.directDependencies === 'undefined')) {
                dependency.children = [];
                let jsonArray = JSON.parse(component.directDependencies);
                for(let z = 0; z < jsonArray.length; z++) {
                    let componentChild = jsonArray[z];

                    fatherComponent.push(componentChild.uuid);

                    let dependencyChild = Object.create(this.dependencyObject);
                    dependencyChild.id = z;
                    dependencyChild.label = componentChild.name + "@" + componentChild.version;

                    const simpleResponse = await fetch(
                        `${apiUrl}/api/v1/dependencyGraph/component/${componentChild.uuid}/directDependencies`,
                        authOpts
                    );

                    if (simpleResponse.status >= 400 && simpleResponse.status < 600) {
                        throw new Error('Failed fetching project dependencies');
                    }

                    let componentDependencies = (await simpleResponse.json()) as DtrackGraphResponse[];
                    if(componentDependencies.length == 0) {
                        dependency.children.push(dependencyChild)
                        fatherComponent.pop();
                        continue
                    }

                    dependencyChild.children = await this.loopDependencies(apiUrl, authOpts, componentDependencies, fatherComponent);
                    dependency.children.push(dependencyChild)
                    fatherComponent.pop();
                }
            }
            fatherComponent.pop();

            dependencies.push(dependency);
        }

        return dependencies;
    }
}